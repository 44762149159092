/* global */
html, body {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
}

/* Form Validation Error - for Login + Register */
.auth-form-validation-error {
    padding: 10px 0;
}

/* Login Form design - Login.js */

#login-form {
    min-height: 100vh;
    background-color: #f5f5f5;
    margin: 0 auto;
    padding: 0 50px;
}

#login-form .login-section {
    padding-top: 5rem !important;
}

.auth-foundation-logo-section {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.auth-login-foundation-logo {
    width: 80%;
}

.auth-foundation-login {
    padding: 5% 15%;
    background-color: white;
    border-radius: 10px;
}

.auth-login-form-button {
    width: 100%;
}

.auth-login-registration {
    text-align: left;
}

.auth-login-image {
    width: 70%;
}

.ant-menu-inline > .ant-menu-item, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 32px;
    line-height: 40px;
    margin-top: unset;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item, .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 32px;
    line-height: 40px;
    list-style-position: inside;
    list-style-type: disc;
    margin-top: unset;
}

.ant-menu-inline .ant-menu-item:not(:last-child), .ant-menu-vertical-left .ant-menu-item:not(:last-child), .ant-menu-vertical-right .ant-menu-item:not(:last-child), .ant-menu-vertical .ant-menu-item:not(:last-child) {
    margin-bottom: unset;
}

@media screen and (max-width: 991px) {
    .auth-login-image {
        width: 60%;
    }

    .auth-login-foundation-logo {
        width: 100%;
    }

    #login-form .login-section {
        padding-top: 3rem !important;
    }
}

.site-layout .site-layout-background {
    background: #fff;
    padding: 24px;
    min-height: 82vh;
    height: 100%;
    margin-bottom: 1%;
}

@media screen and (min-width: 1600px) {
    .site-layout .site-layout-background {
        min-height: 86vh;
    }
}

.site-layout .sitepages {
    padding: 0;
}

.ant-menu-title-content a {
    text-decoration: unset;
}

.ant-layout-header {
    height: 64px;
    padding: 0 50px;
    color: rgba(0, 0, 0, .85);
    line-height: 64px;
    /*background: #1e1e2d;*/
    background: #ffffff;
    border-bottom: 1px solid #f0f2f5;
}

.version-control-active {
    color: #1890ff;
}

/* Animation Keyframes*/
@keyframes wave {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.loadingCms {
    margin-top: 20%;
    padding: 30px 50px;
    text-align: center;
    border-radius: 4px;
}


@media screen and (max-width: 1600px) {

    #loading-screen .blueocean-logo {
        width: 10%!important;
    }

    #loading-screen .qv-logo {
        width: 10%!important;
    }

    #loading-screen .minos-logo {
        width: 20% !important;
    }

    #loading-screen .cronos-logo {
        width: 20% !important;
    }

    #loading-screen .funda-logo {
        width: 35% !important;
    }

    #loading-screen .antos-logo {
        width: 35% !important;
    }
    #loading-screen .tradehub360-logo {
        /*animation: wave 3s linear infinite;*/
        width: 14% !important;
    }

    #loading-screen .equinox-logo {
        /*animation: wave 3s linear infinite;*/
        width: 20% !important;
    }

    #loading-screen .excotrades-logo {
        /*animation: wave 3s linear infinite;*/
        width: 10%!important;
    }

    #loading-screen .allium-logo {
        /*animation: wave 3s linear infinite;*/
        width: 20%!important;
    }

    #loading-screen .ridgeway-logo {
        /*animation: wave 3s linear infinite;*/
        width: 15% !important;
    }
}

@media screen and (max-width: 800px) {
    #loading-screen .blueocean-logo {
        width: 30%!important;
    }

    #loading-screen .qv-logo {
        width: 30%!important;
    }

    #loading-screen .minos-logo {
        width: 40% !important;
    }

    #loading-screen .cronos-logo {
        width: 40% !important;
    }

    #loading-screen .funda-logo {
        width: 80% !important;
    }

    #loading-screen .antos-logo {
        width: 60% !important;
    }
    #loading-screen .tradehub360-logo {
        /*animation: wave 3s linear infinite;*/
        width: 30% !important;
    }

    #loading-screen .equinox-logo {
        /*animation: wave 3s linear infinite;*/
        width: 30% !important;
    }

    #loading-screen .excotrades-logo {
        /*animation: wave 3s linear infinite;*/
        width: 40%!important;
    }

    #loading-screen .allium-logo {
        /*animation: wave 3s linear infinite;*/
        width: 40%!important;
    }

    #loading-screen .ridgeway-logo {
        /*animation: wave 3s linear infinite;*/
        width: 40% !important;
    }

}

#loading-screen .blueocean-logo {
    /*animation: wave 3s linear infinite;*/
    /*width: 15%;*/
    width:280px !important;
}

#loading-screen .qv-logo {
    /*animation: wave 3s linear infinite;*/
    /*width: 15%;*/
    width:145px !important;
}

#loading-screen .minos-logo {
    /*animation: wave 3s linear infinite;*/
    /*width: 12%;*/
    /*width:180px !important;*/
    width:220px !important;

}

#loading-screen .cronos-logo {
    /*animation: wave 3s linear infinite;*/
    /*width: 14%;*/
    width:260px !important;
}

#loading-screen .funda-logo {
    /*animation: wave 3s linear infinite;*/
    /*width: 20%;*/
    width:180px !important;
}

#loading-screen .antos-logo {
    /*animation: wave 3s linear infinite;*/
    /*width: 14%;*/
    width:180px !important;
}

#loading-screen .tradehub360-logo {
    /*animation: wave 3s linear infinite;*/
    /*width: 14%;*/
    width:180px !important;
}

#loading-screen .equinox-logo {
    /*animation: wave 3s linear infinite;*/
    /*width: 30%;*/
    width:180px !important;
}

#loading-screen .excotrades-logo {
    /*animation: wave 3s linear infinite;*/
    /*width: 10%;*/
    width:150px !important;
}

#loading-screen .allium-logo {
    /*animation: wave 3s linear infinite;*/
    /*width: 20%;*/
    width:180px !important;
}

#loading-screen .ridgeway-logo {
    /*animation: wave 3s linear infinite;*/
    /*width: 17%;*/
    width:250px !important;

}

#loading-screen .lynk-logo {
    /*animation: wave 3s linear infinite;*/
    /*width: 17%;*/
    width:180px !important;
}

#loading-screen {
    display: flex;
    flex: 1;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}



/* End of Login Form design */